<template>
  <fki-container
    fixed
    left="0"
    width="100%"
    column
    class="fki-page-download"
  >
    <fki-container-header
      min-height="100px"
      size="30px"
      :color="color"
      :title="title"
    />
    <div class="fki-download-wrapper">
      <div
        v-if="downloads.servicesheets && downloads.servicesheets.length"
        class="fki-download-column"
      >
        <h3 class="fki-downloads-header">
          Service Sheets
        </h3>
        <ul>
          <li
            v-for="item in downloads.servicesheets"
            :key="item.identifier"
          >
            <a
              :href="`https://fotokem.com/${item.path}`"
              target="_blank"
            >
              {{ item.description }}
            </a>
          </li>
        </ul>
      </div>
      <div
        v-if="downloads.fotokemdocuments && downloads.fotokemdocuments.length"
        class="fki-download-column"
      >
        <h3 class="fki-downloads-header">
          FotoKem Documents
        </h3>
        <ul>
          <li
            v-for="item in downloads.fotokemdocuments"
            :key="item.identifier"
          >
            <a
              :href="`https://fotokem.com/${item.path}`"
              target="_blank"
            >
              {{ item.description }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKIFooterNav } from '@/components/FKINav'
import { FKIContainer, FKIContainerHeader } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'

export default {
  name: 'Downloads',
  components: {
    'fki-container': FKIContainer,
    'fki-container-header': FKIContainerHeader,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      title: 'Downloads',
      color: 'white',
      downloads: {}
    }
  },
  async created () {
    await this.getDownloads()
  },
  methods: {
    async getDownloads () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)

        const { data: result } = await Api().get('/downloads')
        this.downloads = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

 .fki-page-download{
    top:70px;
 }

.fki-container-header {
   border-bottom:1px solid rgba(255, 255, 255, 0.12);
}
.fki-download-wrapper {
   position: relative;
    /* float: left; */
    width: 100%;
    max-width: 700px;
    min-width: 380px;
    height: 100%;
    margin: auto;
    padding: 0 24px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    align-items: flex-start;
    min-height: 1em;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    .fki-download-column {
      margin-right: 0;
      width: 100%;
      clear: none;
      float: left;
      padding: 0;
    }
    .fki-downloads-header {
     font-size: 18px;
     text-transform: uppercase;
     text-align: left;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width:100%;
      padding: 0;
      li {
        padding-bottom: 4px;
        text-align: left;
        padding-top: 4px;
        font-size: 14px;
        width:100%;
        a {
          text-decoration: none;
          color: #2BA6DE;
          &:hover {
            color: #1978a3;
          }
          .fki-download-item-header {
            font-size: 18px;
            text-transform: uppercase;
          }
          p {
            font-size:14px;
          }
        }
      }
    }
}

 @media screen and (min-width: 768px) {
 .fki-page-download{
    top:131px;
    bottom:0;
  }
  .fki-download-wrapper {
    display: flex;
    position: relative;
    float: left;
    width: 60%;
    padding: 0;
    max-width: 700px;
    min-width: 380px;
    height: 100%;
    margin: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    align-items: flex-start;
    min-height: 1em;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
    .fki-download-column {
      margin-right: 15px;
      width: auto;
      clear: none;
      float: left;
      padding: 0;
    }
    .fki-downloads-header {
     font-size: 18px;
     text-transform: uppercase;
     text-align: left;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width:100%;
      padding: 0;
      li {
        padding-bottom: 4px;
        text-align: left;
        padding-top: 4px;
        font-size: 14px;
        width:100%;
        a {
          text-decoration: none;
          color: #2BA6DE;
          &:hover {
            color: #1978a3;
          }
          .fki-download-item-header {
            font-size: 18px;
            text-transform: uppercase;
          }
          p {
            font-size:14px;
          }
        }
      }
    }
}
 }
</style>
